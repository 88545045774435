import { setStatePayload } from "@/config/store/StoreItemsModule/helpers"
import { findIndex } from "lodash-es"

export default () => ({
  SET_CONFIG_ITEM(state, { payload, configKey }) {
    const configItemsByConfigKey = [...state.items[configKey]]

    const index = findIndex(configItemsByConfigKey, ({ key }) => key === payload.key)
    configItemsByConfigKey.splice(index, 1, { ...payload, _updated: true })

    state.items[configKey] = configItemsByConfigKey
  },

  SET_INITIAL_ITEMS: setStatePayload("initialItems")
})
