<template lang="pug">
  .config-items-list
    AppOverlayLoader(:state="loading")
    .save-button
      AppSaveButton(
        title="actions.save_changes"
        @save="handleSave"
      )
    BTabs(fill)
      BTab(
        v-for="(items, key) in configItems"
        :title="$t(`maestro.configs_page.tabs.${key}`)"
        :key="key"
      )
        ConfigItemsTable(
          :key="key"
          :config-key="key"
          :config-items="items"
          @change="setConfigItem"
        )
</template>

<script>
  // components
  import { BTabs, BTab } from "bootstrap-vue"
  import SaveModal from "./SaveModal"

  // store modules
  import withStoreModule from "@/mixins/withStoreModule"
  import withModal from "@/mixins/withModal"

  // mixins
  import configItemsStoreModule from "@/config/store/config_items"

  const configItemsMixin = withStoreModule(configItemsStoreModule, {
    name: "configItems",
    readers: {
      loading: "loading",
      configItems: "items",
      initialConfigItems: "initialItems"
    },
    getters: {
      configItemsUpdated: "itemsUpdated"
    },
    mutations: {
      setConfigItem: "SET_CONFIG_ITEM"
    },
    actions: {
      fetchConfigItems: "FETCH_ITEMS",
      saveConfigItems: "UPDATE_ITEMS"
    }
  })

  export default {
    components: {
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      BTabs,
      BTab,
      AppTab: () => import("@/components/elements/AppTab"),
      ConfigItemsTable: () => import("./ConfigItemsTable")
    },

    mixins: [configItemsMixin, withModal],

    mounted() {
      this.fetchConfigItems()
    },

    methods: {
      handleSave() {
        this.$openModal({
          title: this.$t("maestro.configs_page.save_changes"),
          component: SaveModal,
          props: {
            configItemsUpdated: this.configItemsUpdated,
            initialConfigItems: this.initialConfigItems,
            saveAction: this.saveConfigItems
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  .config-items-list
    position: relative

    .save-button
      margin: 20px 0
      text-align: right
</style>
