<template lang="pug">
  .save-modal
    .change-log
      .no-data(v-if="isNoChanges") {{ $t("no_data") }}
      template(v-esle)
        .changes-by-config-key(
          v-for="changesByConfigKey, configKey in changes"
          v-if="!isNoChangesByConfigKey[configKey]"
          :key="configKey"
        )
          .config-key {{ $t(`maestro.configs_page.tabs.${configKey}`) }}
          .change(
            v-for="({ key, initialValue, value }) in changesByConfigKey"
            v-if="initialValue !== value"
            :key="key"
          )
            .key {{ key }}:
            .change-values
              .initial-value(:class="{ true: initialValue, false: !initialValue }") {{ initialValue }}
              .delimeter ->
              .value(:class="{ true: value, false: !value }") {{ value }}
    .actions
      AppButton.cancel(
        title="actions.cancel"
        @click="$emit('close')"
      )
      AppButton.save(
        title="actions.ok"
        :disabled="isNoChanges"
        @click="handleSave"
      )
</template>

<script>
  import { transform, forEach, find, isEmpty, every, mapValues } from "lodash-es"
  import { isConfigEnabled } from "@/helpers/config-items"

  export default {
    components: {
      AppButton: () => import("@/components/elements/AppButton")
    },

    props: {
      configItemsUpdated: {
        type: Object,
        default: () => new Object()
      },
      initialConfigItems: {
        type: Object,
        default: () => new Object()
      },
      saveAction: {
        type: Function,
        default: () => {}
      }
    },

    computed: {
      changes({ configItemsUpdated, initialConfigItems }) {
        return transform(
          configItemsUpdated,
          (obj, configItemsUpdatedByKey, configKey) => {
            forEach(configItemsUpdatedByKey, configItemUpdated => {
              obj[configKey] ||= []
              const initialConfigItem = find(initialConfigItems[configKey], { key: configItemUpdated.key })

              obj[configKey].push({
                key: configItemUpdated.key,
                initialValue: isConfigEnabled(initialConfigItem),
                value: configItemUpdated.value
              })
            })

            return obj
          },
          {}
        )
      },

      isNoChangesByConfigKey({ changes }) {
        return mapValues(changes, changesByConfigKey => {
          return (
            isEmpty(changesByConfigKey) ||
            every(changesByConfigKey, ({ value, initialValue }) => value === initialValue)
          )
        })
      },

      isNoChanges({ isNoChangesByConfigKey }) {
        return every(isNoChangesByConfigKey, item => item)
      }
    },

    methods: {
      handleSave() {
        this.saveAction(this.configItemsUpdated)
        this.$emit("close")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .save-modal

    .change-log
      width: 100%
      height: 310px
      overflow-y: auto

      .no-data
        color: $default-gray
        text-align: center

      .changes-by-config-key
        .config-key
          color: $default-purple
          font-weight: 500

        .change
          height: 60px
          font-size: 0.75em

          &:last-child
            border-bottom: none

          .key
            color: $default-black
            text-align: center

          .change-values
            align-items: center
            color: $default-gray
            display: flex
            justify-content: center
            text-transform: uppercase

            .delimeter
              margin: 0 5px

            .initial-value,
            .value
              &.true
                color: $default-green
              &.false
                color: $default-red

    .actions
      align-items: center
      display: flex
      justify-content: space-evenly

      .cancel
        border: 1px solid $default-purple
        color: $default-purple

      .save
        background-color: $default-purple
        color: $default-white
</style>
