import { api } from "@/config"

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit }) => {
    const response = await api.get(baseURI)

    commit("SET_ITEMS", { ...response.data.data })
    commit("SET_INITIAL_ITEMS", { ...response.data.data })
  },

  UPDATE_ITEMS: async ({ commit }, items) => {
    const response = await api.put(baseURI, { ...items })

    commit("SET_ITEMS", { ...response.data.data })
    commit("SET_INITIAL_ITEMS", { ...response.data.data })
  }
})
