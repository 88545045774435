import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractActions from "./actions"
import extractMutations from "./mutations"
import extractGetters from "./getters"

const BASE_URI = "/maestro/config_items"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["update"]
})

store.mergeState({
  initialItems: {}
})

store.mergeActions(extractActions({ baseURI: BASE_URI }), withLoading)

store.mergeMutations(extractMutations())

store.mergeGetters(extractGetters())

export default store
